import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";


import styles from "assets/jss/material-kit-react/views/components.js";
import TeamSection from "./Sections/TeamSection";

import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(styles);

export default function Pricing(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        brand="INCUBIG"
        to="/"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 150,
          color: "white",
        }}
        {...rest}
      />
 
 <Parallax image={require("assets/img/ai.gif").default}>
        <div className={classes.container}>
          {/* <GridContainer>
            <GridItem> */}
              {/* <div className={classes.brand}> */}
                {/* <h1 className={classes.title}>INCUBIG</h1> */}
                <h1
                 className="landingBrandname" style={{fontSize: "5rem", fontWeight: "bolder", color: "white", letterSpacing: "0.01em"}}>
                Do
                <br/>
                Your
                <br/>
                Research
                </h1>
              {/* </div> */}
              <br/>
              {/* <a href={"https://ai.incubig.org/"}> */}
              <a href={"https://dyr.incubig.org/"}>
              <Button
                  color="danger"
                  size="lg"
                  target="_blank"
                  rel="noopener noreferrer"
                  
              >
                  Get Started
              </Button>
              </a>
            {/* </GridItem>
          </GridContainer> */}
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)} style={{ textAlign:"center"}}>
      <div style={{padding:"10px",margin:"10px"}}>
          <h2 style={{textAlign: "center"}}><b>Pricing</b></h2>
          
          <p> <b>Subscriptions</b></p>
          <p>Incubig Offers following Subscriptions</p>
          <p><b>Basic @ $500/₹ 25K per month</b><p>1. Company profile</p><p>2. Monitor Sector</p><p>3. Assignee Search</p></p>
          <p><b>Standard @ $1000/₹ 50K per month</b><p>1. All in Basic plan</p><p>2. Prior Art Search</p><p>3. Novelty Search</p></p>
          <p><b>Premium @ $2000/₹ 100K per month</b><p>1. All in Standard plan</p><p>2. Freedom to Operate</p><p>3. Patent Filing</p></p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
