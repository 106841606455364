import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";

import styles from "assets/jss/material-kit-react/views/components.js";
import TeamSection from "./Sections/TeamSection";

import Button from "components/CustomButtons/Button.js";
import "./landingPage.css";

const useStyles = makeStyles(styles);

export default function Landing(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        brand="INCUBIG"
        to="/"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 150,
          color: "white",
        }}
        {...rest}
      />
      <Parallax image={require("assets/img/ai.gif").default}>
        <div className={classes.container}>
          {/* <GridContainer>
            <GridItem> */}
          {/* <div className={classes.brand}> */}
          {/* <h1 className={classes.title}>INCUBIG</h1> */}
          <h1 className="landingBrandname">
            Don't
            <br />
            react to change,
            <br />
            know future landscape.
          </h1>
          <p className="landingPara brandNamePara">
            For academic and industry professionals
          </p>
          {/* </div> */}
          <br />
          {/* <a href={"https://ai.incubig.org/"}> */}
          <a href={"https://dyr.incubig.org/"}>
            <Button
              color="danger"
              size="lg"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get Started
            </Button>
          </a>
          {/* </GridItem>
          </GridContainer> */}
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <TeamSection />
      </div>
      <Footer />
    </div>
  );
}
