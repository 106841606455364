import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";


import styles from "assets/jss/material-kit-react/views/components.js";
import TeamSection from "./Sections/TeamSection";

import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(styles);

export default function About(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        brand="INCUBIG"
        to="/"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        style={{padding: "8px"}}
        changeColorOnScroll={{
          height: 150,
          color: "white",
        }}
        {...rest}
      />
      <Parallax image={require("assets/img/ai.gif").default}>
        <div className={classes.container}>
          {/* <GridContainer>
            <GridItem> */}
              {/* <div className={classes.brand}> */}
                {/* <h1 className={classes.title}>INCUBIG</h1> */}
                <h1
                 className="landingBrandname" style={{fontSize: "5rem", fontWeight: "bolder", color: "white", letterSpacing: "0.01em"}}>
                Do
                <br/>
                Your
                <br/>
                Research
                </h1>
              {/* </div> */}
              <br/>
              {/* <a href={"https://ai.incubig.org/"}> */}
              <a href={"https://dyr.incubig.org/"}>
              <Button
                  color="danger"
                  size="lg"
                  target="_blank"
                  rel="noopener noreferrer"
                  
              >
                  Get Started
              </Button>
              </a>
            {/* </GridItem>
          </GridContainer> */}
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)} style={{ textAlign:"center"}}>
        <br/>
        <h4 >About Us</h4>
        <br/>
        <p>Goa based Incubig is an AI-powered patent and research analytics platform, helping individuals and organisations to easily access patent analytics in real-time, with a minimum of 60% cost savings, and increased ROI on their research and patents. Incubig’s proprietary artificial intelligence engine is a next generation patent and research analytics engine integrated with patent expertise, which is deep learning millions of patents and research data and providing highly customised and predictive insights on patents, future product applications, next technology trends, next invention area, infringement risks, key players, density and much more.</p>
        <br/>
        <br/>
      </div>
      <Footer />
    </div>
  );
}
