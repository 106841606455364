import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import team1 from "assets/img/faces/michael.jfif";
import team2 from "assets/img/faces/satish.jfif";
import team3 from "assets/img/faces/chelsea.jfif";

import AIM from "assets/img/entities/aim.jfif";
import NITI from "assets/img/entities/goi.png";
import GOSK from "assets/img/entities/gosk.jfif";

import TOI from "assets/img/entities/toi.png";
import HINDU from "assets/img/entities/hindu.png";
import TIE from "assets/img/entities/tie.png";
import Partners from "components/partners/partners";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <>
      {/* <div className={classes.section}>
        <h2 className={classes.title}>Acclaimed By</h2>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img src={AIM} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Atal Incubation Center
                  <br />
                  <small className={classes.smallTitle}>Government of India</small>
                </h4>
                
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img src={GOSK} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Ministry of SMEs & Start-Ups
                  <br />
                  <small className={classes.smallTitle}>Government of South Korea</small>
                </h4>
                
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img src={NITI} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Niti Ayog
                  <br />
                  <small className={classes.smallTitle}>Government of India</small>
                </h4>
                
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div> */}

<div className={classes.section}>
<h2 className={classes.title}>Our Partners in Research</h2>
<div>
      <Partners isMoving={true} />
      </div>
      </div>

      <div className={classes.section}>
        <h2 className={classes.title}>Word of appreciation</h2>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img src={team1} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Michael Wright
                  <br />
                  <small className={classes.smallTitle}>Founding Partner, Intercepting Horizons</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    The process is fast, efficient, cost effective and the results have been outstanding. Strategically we can do much more at a fraction of the cost and at incredible speed.
                    {/* Through our partner relationship with Incubig, we have been able to provide our clients with patent activity insights */}
                    {/* Through our partner relationship with Incubig, we have been able to provide our clients with patent activity insights ranging from identifying converging technologies that are creating new platforms and ecosystems at scale, to VC's doing start up patent due diligence, to companies evaluating their patent portfolios for licensing and new directions.  */}
                  </p>
                </CardBody>
                <CardFooter className={classes.justifyCenter}>
                  <a href="https://www.linkedin.com/in/michael-wright-09aa5b6/">
                    <i className={classes.socials + " fab fa-linkedin"} />
                  </a>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img src={team2} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Satish Mehta
                  <br />
                  <small className={classes.smallTitle}>Director, Digital India Fund</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    I have been working with Incubig since the last two years, and they have never disappointed me. Reports are detailed and meet all the expectations. Their report on Covid19 in early 2020 was shared with the government and was well appreciated.
                  </p>
                </CardBody>
                <CardFooter className={classes.justifyCenter}>
                  <a href="https://www.linkedin.com/in/mehtasatish/">
                    <i className={classes.socials + " fab fa-linkedin"} />
                  </a>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img src={team3} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Chelsea Han
                  <br />
                  <small className={classes.smallTitle}>Manager, NIPA</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    Incubig's solution & platform is needed in South Korea. One of the top companies in KSGC 2019 competition, signed MoUs and joint venture agreements with leading corporations.
                  </p>
                </CardBody>
                <CardFooter className={classes.justifyCenter}>
                  <a href="https://www.linkedin.com/in/chelseahan0/">
                    <i className={classes.socials + " fab fa-linkedin"} />
                  </a>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <div className={classes.section}>
        <h2 className={classes.title}>Media Mentions</h2>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img src={TOI} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Times Of India
                </h4>
                
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img src={HINDU} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  The Hindu
                </h4>
                
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img src={TIE} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Indian Express
                </h4>
                
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </>
  );
}
